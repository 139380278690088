
import React, { Fragment, useCallback, useEffect } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import Menu from "../imagelist/menu";
import EntityKeypad from "../../components/Keypad";

import Wrapper from "../../components/Wrapper";

import { useLocalStorage } from '../../components/hooks';

const PREFIX = 'List';

const classes = {
    listItem: `${PREFIX}-listItem`,
    listLink: `${PREFIX}-listLink`,
    listImg: `${PREFIX}-listImg`,
    titleDiv: `${PREFIX}-titleDiv`,
    titleFlex: `${PREFIX}-titleFlex`,
    title1: `${PREFIX}-title1`,
    title2: `${PREFIX}-title2`,
    title3: `${PREFIX}-title3`,
    numCode: `${PREFIX}-numCode`,
    playButton: `${PREFIX}-playButton`
};

const Root = styled('ul')((
    {
        theme
    }
) => ({
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    [`& .${classes.listItem}`]: {
        textDecoration: 'none',
        display: 'block',
        cursor: 'pointer',
        padding: '0',
        color: theme.palette.primary.main,
        background: theme.palette.mode === 'light' ? '#efefef' : '#1b1b1b',
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        minHeight: '48px',
        margin: '5px 0px 5px 0px',
        overflow: 'hidden',
        alignItems: 'stretch',


    },

    [`& .${classes.listLink}`]: {
        textDecoration: 'none',
        //   color: '#000',

    },

    /* STANDART
    listImg: {
        maxWidth: '110px',
        display: 'flex',

    },*/

    /* CUSTOM 1 */

    [`& .${classes.listImg}`]: {
        width: 130,
        minWidth: 130,
        minHeight: 100,
        maxHeight: 125,
    },

    /* End CUSTOM 1*/

    [`& .${classes.titleDiv}`]: {
        minWidth: '0',
        overflow: 'hidden',


        padding: '10px 28px 10px 8px'

    },

    [`& .${classes.titleFlex}`]: {

        display: 'flex',
        flexGrow: '1',

        alignItems: 'center',
        position: 'relative',
        minHeight: '80px',
    },

    [`& .${classes.title1}`]: {
        fontSize: '15px',
        textTransform: 'uppercase',
        marginBottom: '0',
        paddingRight: '25px',
        lineHeight: '1.15',
        fontWeight: 'bold',
    },

    [`& .${classes.title2}`]: {
        fontSize: '14px',
        lineHeight: '1.2',
        marginTop: '3px',
        paddingRight: '20px',
    },

    [`& .${classes.title3}`]: {
        fontSize: '14px',
        marginBottom: '0px',
        marginTop: '2px',
        fontWeight: 'normal',
    },

    [`& .${classes.numCode}`]: {
        position: 'absolute',
        right: '10px',
        bottom: '7px',
        fontSize: '13px',
        fontWeight: 'bold'
    },

    [`& .${classes.playButton}`]: {
        position: 'absolute',
        right: '6px',
        top: '50%',
        transform: 'translateY(-50%)',
    }
}));

const List = ({ entity, pageContext }) => {
    const { strapiChildren: entities } = entity;

    const { defaultLocale, locale } = pageContext;

    const [scrollPos, setScrollPos] = useLocalStorage(`module.renoir-list-scrollpos-${locale}.${entity.identifier}`, 0);
    const onNavigate = useCallback((e) => {
        setScrollPos(e.view.pageYOffset);
    }, [setScrollPos]);

    useEffect(() => {
        window.scrollTo(0, scrollPos);
    }, [scrollPos]);

    return (
        <Root>
            {entities.map((entity, n) => {
                const listRole = _.find(entity.Roles, { strapi_component: 'role.list-child' });

                const title1 = listRole?.line1 || entity.title || '';
                const title2 = listRole?.line2 || false;
                const title3 = listRole?.line3 || false;

                const numCode = parseInt(listRole?.numCode || 0) || '';

                const image = entity.smallImage ? getImage(entity.smallImage.localFile) : null;
                return (
                    <Fragment key={entity.identifier}>
                        <Link to={getEntityUrl({ entity, defaultLocale })}
                            className={classes.listLink}
                            onClick={onNavigate}
                        >
                            <li key={entity.identifier} className={classes.listItem}>

                                {image !== null ?
                                    <GatsbyImage className={classes.listImg} image={image} alt={title1} layout="fullWidth" /> : <></>}
                                <div className={classes.titleFlex}>
                                    <div className={classes.titleDiv}>
                                        <div className={classes.title1}>{title1}</div>
                                        <div className={classes.title2}>{title2}</div>
                                        <div className={classes.title3}>{title3}</div>
                                        <div className={classes.numCode}>{numCode}</div>
                                        <div className={classes.playButton}>
                                            <img src={`/images/bigarrow.png`} alt="play" />
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </Link>

                    </Fragment>
                );

            })}
        </Root>
    );

};

export default List;
