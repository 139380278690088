import React from 'react'
import _ from "lodash";

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import ListItemView from '../views/ListItemView'

import { getEntityUrl } from "../../../util";

const ListItemController = ({entity, children, pageContext }) => {
    //if (children) return <>{children}</>;//TODO - use this if you want to override webflow completely, i.e. inject other components, in an af-el
    
    if (!entity) return <></>;

    
    const listRole = _.find(entity.Roles, { strapi_component: 'role.list-child' });

    const title1 = listRole?.line1 || entity.title || '';
    const title2 = listRole?.line2 || false;
    const title3 = listRole?.line3 || false;

    const image = entity.smallImage ? getImage(entity.smallImage.localFile) : null;
    const { defaultLocale } = pageContext;
    const url = getEntityUrl({ entity, defaultLocale });
    
    return <ListItemView>
        
        <list-item-link to={url} target="_blank">
            <list-item-title1>{title1}</list-item-title1>
            { title2 !== false && <list-item-title2>{title2}</list-item-title2>}
            { title3 !== false && <list-item-title3>{title3}</list-item-title3> }
            { image !== null ? <list-item-image><GatsbyImage image={image} alt={title1} layout="fullWidth" /></list-item-image> : <></>}
        </list-item-link>
    </ListItemView>
}

export default ListItemController;