import React, { useState, useCallback, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import  { CaptionDiv } from '../Carousel';

const ZoomWrapper = ({ image, caption, zoomable, fullscreenMode }) => {
    const [zoomRef, setZoomRef] = useState(null);
    const zoomRefCb = useCallback((node) => {
        setZoomRef(node);
    }, [setZoomRef]);

    useEffect(() => {
        const centerZoom = () => {
            setTimeout(() => {if (zoomRef) zoomRef.centerView()}, 50);
        }

        window.addEventListener('resize', centerZoom);
        return () => window.removeEventListener('resize', centerZoom)
    }, [zoomRef]);

    const onImageLoad = useCallback(() => { if (zoomRef) zoomRef.centerView(undefined, 1) }, [zoomRef]);

    const zoomWrapperStyle = { width: '100%' };
    if (fullscreenMode) {
        zoomWrapperStyle.maxHeight = '-webkit-fill-available';
        zoomWrapperStyle.height = '100vh';
    }

    return <TransformWrapper centerOnInit={true} disabled={zoomable !== true} ref={zoomRefCb} >
        <TransformComponent wrapperStyle={zoomWrapperStyle} wrapperClass='zoomWrapper'>
            <GatsbyImage
                onLoad={onImageLoad}
                image={getImage(image)}
                alt="" objectFit="contain" loading="eager"
                imgStyle={{ position: 'static', maxHeight: '100vh' }} />
        </TransformComponent>
        {caption !== '' &&
            <CaptionDiv>{caption}</CaptionDiv>}
    </TransformWrapper>
};

export default ZoomWrapper;
