import React, { Component, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from "lodash";
import Pannellum, { getViewer } from 'react-pannellum';

import FullscreenWrapper from '../FullscreenWrapper';

const ThreeSixtyWrapper = (props) => {
    const { entity, threeSixtyImage, fullscreenMode, viewer, setViewer, component, position } = props;
    
    const [mousedownTime, setMousedownTime] = useState(null);
    const [triggerTopBar, setTriggerTopBar] = useState(true);
    
    const onMouseDown = useCallback(() => {
        setMousedownTime(new Date().getTime());
    }, [setMousedownTime]);
    
    const onMouseUp = useCallback(() => {
        const curTime = new Date().getTime();

        if (mousedownTime === null || (curTime - mousedownTime) < 200) { setTriggerTopBar(true); }
        else { setTriggerTopBar(false); }
    }, [mousedownTime, setTriggerTopBar]);

    const catchClick = useCallback((e) => {
        if (!triggerTopBar) {
            e.preventDefault();
            e.stopPropagation();
            setTriggerTopBar(true);
            return false;
        }
        return true;
    }, [triggerTopBar, setTriggerTopBar]);

    useEffect(() => {
        if (viewer) {
            viewer.off('mousedown');
            viewer.off('mouseup');
            viewer.on('mousedown', onMouseDown);
            viewer.on('mouseup', onMouseUp);
        }
    }, [viewer, onMouseDown, onMouseUp]);

    
    const fsRef = useRef();
    useEffect(() => {
        if (fsRef?.current) fsRef.current.addEventListener('click', catchClick);
        return () => fsRef.current?.removeEventListener('click', catchClick);
    }, [fsRef, catchClick]);


    const style = useMemo(() => {
        return {
            width: "100%",
            height: fullscreenMode ? '90vh' : "90vh",//TODO - remove/style?
        }
    }, [fullscreenMode]);

    useEffect(() => {
        if (viewer) {
            viewer.resize();
        }
    }, [fullscreenMode]);

    const pannellumConfig = {
                autoLoad: true,
            };
    
    const optConfigKeys = [ 'title', 'description', 'minYaw', 'maxYaw', 'minPitch', 'maxPitch'];
    optConfigKeys.forEach(key => {
        if (component[key]) pannellumConfig[key] = component[key];
    });
    
    if (position) {
        ['pitch', 'yaw', 'hfov'].forEach(k => 
            pannellumConfig[k] = position[k]
        );
    }
    
    return <FullscreenWrapper ref={fsRef} {...props} >
        <Pannellum
            id="threeSixtyViewer"
            sceneId={entity.identifier}
            {...threeSixtyImage}
            config={pannellumConfig}
            style={style}
            onPanoramaLoaded={() => setViewer(getViewer())}
        />
    </FullscreenWrapper>

}

export default ThreeSixtyWrapper;