import React from "react";
import Parser from "html-react-parser";
import _ from "lodash";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import TruncateWrapper from './truncateWrapper';

const Text = ({ component, entity, files, ...props }) => {

    const replaceFn = domNode => {

        if (domNode.name === 'img' && domNode.attribs?.src) {

            const name = domNode.attribs.src.split('/').pop().replace(/\.[^/.]+$/, "");
            const img = _.find(files.nodes, { name });

            if (img) {
                const styles = {};
                if (domNode.attribs?.style) {
                    styles.display = 'inline-block';
                    domNode.attribs?.style.split(';').forEach(e => e.replace(
                        /\s*([^:]+)\s*:\s*([^;]+)\s*/g,
                        (m, p, v) => {
                            p = p.replace(/-(.)/g, (m, p) => p.toUpperCase());
                            styles[p] = v
                        }
                    ));
                }
                return <div style={styles}><GatsbyImage image={getImage(img)} alt="" /></div>
            } else
                return domNode;
        }

        //if (domNode.name === 'p') domNode.name='div';

        return domNode;
    };
    return <div className="richtext">
        <TruncateWrapper 
            lines={3} 
            readMoreText={component.truncatedLabel||'More'}
            readLessText={component.expandedLabel||'Less'}
            enabled={component.truncable}
            truncated={component.truncated}
            >
            {Parser(component.body || '', { replace: replaceFn })}
        </TruncateWrapper>
    </div>
};

export default Text;
