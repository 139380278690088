/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ListItemView from './ListItemView'

const scripts = [
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '61791b6eb4e3e471a6477e79'
    htmlEl.dataset['wfSite'] = '61791b6eb4e3e41fe0477e78'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      'header-image': [],
      'headline': [],
      'list-item-view': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/erwin-list.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            {map(proxies['header-image'], props => <div {...{...props, className: `af-class-header-image-container ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="/images/501.jpg" loading="lazy" sizes="100vw" height={100} srcSet="/images/501-p-500.jpeg 500w, /images/501.jpg 750w" alt="" className="af-class-header-image" /></React.Fragment>}</div>)}
            <ul role="list" className="af-class-audiolist">
              {map(proxies['headline'], props => <li {...{...props, className: `af-class-list-headline-1 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>headline</React.Fragment>}</li>)}
              {map(proxies['list-item-view'], props => <ListItemView.Controller {...props}>{props.children}</ListItemView.Controller>)}
            </ul>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */