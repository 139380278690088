import React, { useState, useEffect, useRef } from 'react'
import './truncate.css'


const ReadMoreText = (props) => {
    const {
        type = 'html',
        readMoreText = 'More',
        readLessText = 'Less',
        lines = 3,
        enabled = false,
        children = [],
        truncated=true,
    } = props;

    const [isOverflowing, setIsOverflowing] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const containerRef = useRef(null);

    useEffect(() => {
        const el = containerRef.current;

        if (!el) return;

        if (el.scrollHeight > el.clientHeight) {
            setIsOverflowing(true);
            if (!truncated) setExpanded(true);
        }

    }, [props.lines, containerRef, setExpanded, truncated])

    const toggleExpand = () => {
        setExpanded(!expanded)
    }

    const classNames = ' rm-typography ' + (expanded ? '' : ' rm-text-container ') + (isOverflowing ? ' rm-overflow-hidden ' : '')

    return enabled ?
        (
            <div className="rm-container">
                <div ref={containerRef} className="rm-text-wrapper">
                    {
                        type === 'html' ?
                            <div className={classNames} style={{
                                lineClamp: lines,
                                WebkitLineClamp: lines,
                            }} >{children}</div>
                            :
                            <div className={classNames} style={{
                                lineClamp: lines,
                                WebkitLineClamp: lines,
                            }}>
                                {children}
                            </div>
                    }

                </div>
                {
                    isOverflowing ?
                        (
                            <div className="rm-action-button-container">
                                {
                                    !expanded ?
                                        <button className="rm-more-button" onClick={toggleExpand} >{readMoreText}</button> :
                                        <button className="rm-less-button" onClick={toggleExpand}>{readLessText}</button>
                                }
                            </div>
                        )
                        : null
                }
            </div>
        )
        :
        <>{children}</>

}


export default ReadMoreText;