import React, { Fragment, useCallback, useEffect } from "react"
import { styled } from "@mui/material/styles"
import _ from "lodash"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { getEntityUrl, getAccessoryIcon } from "../../util"

import Menu from "./menu"
import EntityKeypad from "../../components/Keypad"

import Wrapper from "../../components/Wrapper"

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useLocalStorage } from '../../components/hooks';
const PREFIX = "List"


const classes = {
  listItem: `${PREFIX}-listItem`,
  listLink: `${PREFIX}-listLink`,
  listImg: `${PREFIX}-listImg`,
  titleDiv: `${PREFIX}-titleDiv`,
  titleFlex: `${PREFIX}-titleFlex`,
  title1: `${PREFIX}-title1`,
  title2: `${PREFIX}-title2`,
  title3: `${PREFIX}-title3`,
  numCode: `${PREFIX}-numCode`,
  playButton: `${PREFIX}-playButton`
}

const Root = styled("ul")(({ theme }) => ({
  listStyleType: "none",
  margin: "0",
  padding: 0,


  [`& .${classes.listItem}`]: {
    textDecoration: "none",
    display: "block",
    cursor: "pointer",
    padding: "0px 0 0px 0px",
    color: theme.palette.primary.main,
    background: theme.palette.mode === "light" ? "#ffffff" : "#333",
    borderBottom: '2px solid',
    borderColor: theme.palette.mode === "light" ? "#999" : "#737373",
    listStyle: "none",
    display: "flex",
    flexDirection:"row",
    justifyContent: "flex-start",
    minHeight: "48px",
    margin: "0px 8px",
    overflow: "hidden",
    alignItems: "center",
    position: 'relative',
  },

  [`& .${classes.listLink}`]: {
    textDecoration: "none"
    //   color: '#000',
  },

  [`& .${classes.listImg}`]: {
    width: "100px",
    minWidth: 100,
    height: "100px",
    margin: '12px 0px',
    marginRight: 8,
    "@media (min-width:768px)": {
    	width: "100px",
    minWidth: 100,
    height: "100px",
	},

  },


  [`& .${classes.titleDiv}`]: {
    width: "100%",
    overflow: "hidden",
    padding: "0px 28px 0px 0px",
    marginLeft: "0px",
  },

  [`& .${classes.titleFlex}`]: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
    position: "relative",
    minHeight: "60px",
  },

  [`& .${classes.title1}`]: {
    fontSize: '1rem',
    textTransform: 'none',
    marginBottom: 0,
    lineHeight: 1.2,
    fontWeight: 400,
    "@media (min-width:768px)": {
    	fontSize: '1.2rem',
	},
  },

  [`& .${classes.title2}`]: {
    fontSize: '1rem',
    lineHeight: 1.2,
    marginTop: 0,
    "@media (min-width:768px)": {
    	fontSize: '1.2rem',
	},
  },

  [`& .${classes.title3}`]: {
    fontSize: "1rem",
    lineHeight: 1.2,
    marginBottom: "0px",
    marginTop: "0px",
    fontWeight: "normal",
    "@media (min-width:768px)": {
    	fontSize: '1.2rem',
	},
  },

  [`& .${classes.numCode}`]: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    fontSize: 15,
    fontWeight: 'bold',
  },

  [`& .${classes.playButton}`]: {
    position: "absolute",
    right: "0px",
    top: "50%",
    transform: "translateY(-40%)"
  }
}))

const List = ({ entity, pageContext }) => {
  const { strapiChildren: entities } = entity

  const { defaultLocale, locale } = pageContext

  const [scrollPos, setScrollPos] = useLocalStorage(`module.wma-list-scrollpos-${locale}.${entity.identifier}`, 0);
  const onNavigate = useCallback((e) => {
    setScrollPos(e.view.pageYOffset);
  }, [setScrollPos]);

  useEffect(() => {
    window.scrollTo(0, scrollPos);
  }, [scrollPos]);

  return (
    <Root>
      {entities.map((entity, n) => {
        const listRole = _.find(entity.Roles, {
          strapi_component: "role.list-child"
        })

        const title1 = listRole?.line1 || entity.title || ""
        const title2 = listRole?.line2 || false
        const title3 = listRole?.line3 || false

        const numCode = parseInt(listRole?.numCode || 0) || ""

        const AccessoryIcon = getAccessoryIcon({ defaultIcon: ArrowForwardIosIcon, role: listRole});

        const image = entity.smallImage
          ? getImage(entity.smallImage.localFile)
          : null
        return (
          <Fragment key={entity.identifier}>
            <Link
              to={getEntityUrl({ entity, defaultLocale })}
              className={classes.listLink}
              onClick={onNavigate}
            >
              <li key={entity.identifier} className={classes.listItem}>
                {image !== null ? (
                  <GatsbyImage
                    className={classes.listImg}
                    image={image}
                    alt={title1}
                    layout="fullWidth"
                  />
                ) : (
                  <></>
                )}
                <div className={classes.titleFlex}>
                  <div className={classes.titleDiv}>
                    <div className={classes.title1}>{title1}</div>
                    <div className={classes.title2}>{title2}</div>
                    <div className={classes.title3}>{title3}</div>
                    <div className={classes.playButton}>
                        <AccessoryIcon color="secondary" fontSize="small" />
                    </div>
                  </div>
                </div>
                <div className={classes.numCode}>{numCode}</div>
              </li>
            </Link>
          </Fragment>
        )
      })}
    </Root>
  )
}

export default List
