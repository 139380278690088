import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { LinearProgress, Slide } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const PREFIX = 'ProgressBar';

const classes = {
    blockUI: `${PREFIX}-blockUI`,
    bar: `${PREFIX}-bar`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.blockUI}`]: {
        position: "absolute",
        zIndex: 1010,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: '100%',
        width: '100%',
        minHeight: '2em',
        cursor: 'wait',
        overflow: 'hidden',  
        opacity: 0.5,
        filter: 'alpha(opacity=50)',
        backgroundColor: 'rgb(184, 184, 184)',
    },
    [`& .${classes.bar}`]: {
        width: '100%',
        backgroundColor: '#fff',//TODO - global bg col
        zIndex: 65535,
        paddingTop: '18px',
    },
});

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const ProgressBar = ({ progress, children, style, installing }) => {

    const [showing, setShowing] = useState(false);

    useEffect(() => {
        if (installing && progress > 0 && progress < 100) //show instantly, "ease" out when done
            setShowing(true);
        else
            setTimeout(() => setShowing(false), 1000);
    }, [progress, installing]);

    return (
        (<Root>
            <div className={showing ? classes.blockUI : ''} />
            <Slide style={style} direction="up" in={showing} >
                <div className={classes.bar}>
                    <LinearProgressWithLabel value={progress} />
                </div>
            </Slide>
        </Root>)
    );
};

export default ProgressBar;