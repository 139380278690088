import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from "lodash";

import { addHotSpot, getAllScenes, getHfov, getPitch, getYaw, removeHotSpot } from 'react-pannellum';

import { navigate } from "gatsby";

import { getEntityUrl } from "../../util";

import ThreeSixtyWrapper from './wrapper';

import { useLocalStorage } from '../hooks';

const ThreeSixtyViewer = (props) => {
    const { entity, component, files, fullscreenMode, pageContext } = props;
    const { defaultLocale } = pageContext;


    const image = _.find(files.nodes, { id: component.threeSixtyImage.localFile___NODE });

    const threeSixtyImage = useMemo(() => {
        return {
            imageSource: image.publicURL,
            equirectangularOptions: {},
        }
    }, [component]);

    const [viewer, setViewer] = useState();
    const removeHotspots = useCallback(() => {
        entity?.strapiChildren?.forEach(c => {
            try { removeHotSpot(c.identifier, entity.identifier); } catch (e) { };
        });
        component.customMarker?.forEach((m, n) => {
            try { removeHotSpot(`${m.entity.identifier}_custom${n}`, entity.identifier); } catch (e) { };
        });

    }, [entity, component]);

    const [position, setPosition] = useLocalStorage(`component.threesixtyviewer.${entity.identifier}.position`);
    const savePosition = useCallback(() => {
        setPosition({
            pitch: getPitch(),
            yaw: getYaw(),
            hfov: getHfov()
        });
    }, [setPosition]);

    useEffect(() => {
        const oldScenes = getAllScenes();//bug in react-panellum - old scenes' hotspots are shown

        oldScenes?.forEach((scene) => {
            Object.keys(scene).forEach(s => {
                scene[s].hotSpots?.forEach(hotspot => {
                    removeHotSpot(hotspot.id, entity.identifier);
                });
            });
        });

        window.addEventListener('popstate', removeHotspots);

        entity.strapiChildren.forEach(c => {
            if (viewer) {
                const { Roles, identifier, locale } = c;

                const role = _.find(Roles, { strapi_component: 'role.three-sixty-child' });
                const url = getEntityUrl({ entity: c, defaultLocale });
                if (role) {
                    addHotSpot(
                        {
                            pitch: parseFloat(role.pitch),
                            yaw: parseFloat(role.yaw),
                            type: role.type || "info",
                            text: role.hotspotLabel,
                            //sceneId: identifier,
                            id: identifier,
                            URL: url,
                            clickHandlerFunc: (e) => {
                                if (e.target.nodeName === 'SPAN') {
                                    removeHotspots();
                                    savePosition();
                                    navigate(url);
                                }
                                e.preventDefault();
                                return false;

                            },

                        },
                        entity.identifier
                    );
                }
            }
        });

        component.customMarker?.forEach((m, n) => {
            if (m.entity) {
                const url = getEntityUrl({ entity: m.entity, defaultLocale });
                addHotSpot(
                    {
                        pitch: parseFloat(m.pitch),
                        yaw: parseFloat(m.yaw),
                        type: m.type || "info",
                        text: m.hotspotLabel,
                        //sceneId: m.entity.identifier,
                        id: `${m.entity.identifier}_custom${n}`,
                        URL: url,
                        clickHandlerFunc: (e) => {
                            if (e.target.nodeName === 'SPAN') {
                                removeHotspots();
                                savePosition();
                                navigate(url);
                            }
                            e.preventDefault();
                            return false;

                        },

                    },
                    entity.identifier
                );
            }
        })

        return () => removeHotspots();
    }, [entity, viewer]);

    const threeSixtyProps = { viewer, setViewer, threeSixtyImage, position };

    return <ThreeSixtyWrapper {...props} {...threeSixtyProps} />

}

export default ThreeSixtyViewer;