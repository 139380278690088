import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import Header from "./header";
import Wrapper from "../../components/Wrapper";
import ComponentsWrapper from "../../components/ComponentsWrapper";

import EntityList from "../../components/List";
import EntityKeypad from "../../components/Keypad";

const EntityPage = (props) => {
    const { entity, config, selectedModule } = props;
    const [keypadShown, setKeypadShown] = useState(config?.showKeypadFirst || false);
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };
    return selectedModule !== 'material' ?
        <></>
        :
        <Wrapper {...props} title={entity.title /*TODO - discuss/define*/} keypadSwitch={config?.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
            {keypadShown ?
                <EntityKeypad {...{ entity }} {...props} />
                :
                <div>
                    <Header entity={entity} />
                    <ComponentsWrapper {...props} />

                    {
                        entity.strapiChildren.length > 0 &&
                        <EntityList {...props} />
                    }

                </div>
            }
        </Wrapper>
};

export default EntityPage;
