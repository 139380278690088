import React from 'react';
import { styled } from '@mui/material/styles';

import { IconButton } from '@mui/material';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

const StyledFiberManualRecordIcon = styled(FiberManualRecord)({
    fontSize: "10px",
});

const StyledIndicatorIconButton = styled(
    IconButton,
    { shouldForwardProp: (propName) => !propName.startsWith('$') }
)(({ $active }) => ({
    cursor: "pointer",
    transition: "200ms",
    padding: 4,
    filter: "drop-shadow(1px 1px 0px #000000ad)",
    color: $active ? "#25adfb" : "#efefef",
    '&:hover': {
        color: $active ? "#909090" : "#fff",
    },
    '&:active': {
        color: $active ? "#909090" : "#fff",
    }
}));


export const CaptionDiv = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    zIndex: 65535,
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    color: '#ffffff',
    padding: '5px 5px',
    width: '100%',
    overflowWrap: 'break-word',
    background: 'rgb(0 0 0 / 25%)',
}));

const Carousel = ({carouselProps, className, fullscreenMode, children, hasCaption}) => {

    const indicator = (onClick, isSelected, index, label) => {
        return <StyledIndicatorIconButton
            $active={isSelected}
            key={index}
            onClick={onClick}
            style={{
                marginTop: fullscreenMode ? '-25px' : hasCaption ? '-30px' : '18px',
            }}
        >
            <StyledFiberManualRecordIcon />
        </StyledIndicatorIconButton>
    }

    return <ResponsiveCarousel {...carouselProps} className={className} renderIndicator={indicator} >
        {children}
    </ResponsiveCarousel>

}

export default Carousel;